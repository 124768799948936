// Page layout:
import Navigation from '@/components/Navigation';
import Carousel from '@material-tailwind/react/components/Carousel';
import IconButton from '@material-tailwind/react/components/IconButton';

// Helpers:
import GlobalLoader from '@/components/GlobalLoader';
import { useZIndex } from '@/contexts/ZIndex';
import {
  executeOnBigQuery,
  getKpiData,
  loadFilters,
  nullImputation,
  roundToTheNearestTen,
} from '@/lib';
import TemplatePart1 from '@/templates/template.part1';
import TemplatePart2 from '@/templates/template.part2';
import type {
  ActiveFilters,
  FilterSelect,
  IdLabelObject,
  SortField,
  SortId,
} from '@/types';
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import type { Query } from 'database/bigQueryHelper';
import type { Filter } from 'database/queryHelper';
import { clone, find, isEmpty, isEqual, omit } from 'lodash';
import type { GetStaticProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type EntreprisesProps = {
  filters: FilterSelect[];
  initialKpiData: Record<string, Query>;
};

const _getPagesQueries = (filter?: Filter) => {
  // apply filter to queries
  const queries = (filter?: Filter) => ({
    part1: {
      title: 'KPI 1',
      props: {
        dimensions: ['num_branche', 'branche as agg1_label'],
        aggregationFunction: 'count_distinct',
        measure: 'siren',
        measureAlias: 'nb',
        table: 'opco2i_etab_ent',
        orderBy: ['nb'],
        orderDirection: 'DESC',
        filter: {
          branche: 'IS NOT NULL',
          num_branche: 'NOT IN (9999)',
          annee: 2024,
          ...filter,
        },
      },
    } as Query,
    part1interindus: {
      title: 'KPI 1',
      props: {
        dimensions: ['1'],
        aggregationFunction: 'count_distinct',
        measure: 'siren',
        measureAlias: 'nb',
        table: 'opco2i_etab_ent',
        orderBy: ['nb'],
        orderDirection: 'DESC',
        filter: {
          branche: 'IS NOT NULL',
          num_branche: 'NOT IN (9999)',
          annee: 2024,
        },
      },
    } as Query,
    part2: {
      title: 'KPI 2',
      props: {
        dimensions: [
          'num_branche',
          'branche as agg1_label',
          'num_taille_entreprise',
          'taille_entreprise as agg2_label',
        ],
        aggregationFunction: 'count_distinct',
        measure: 'siren',
        measureAlias: 'nb',
        table: 'opco2i_etab_ent',
        orderBy: ['num_taille_entreprise', 'nb'],
        orderDirection: 'DESC',
        filter: {
          branche: 'IS NOT NULL',
          num_branche: 'NOT IN (9999)',
          annee: 2024,
          ...filter,
        },
      },
    } as Query,
    part2interindus: {
      title: 'KPI 2',
      props: {
        dimensions: [
          'num_taille_entreprise',
          'taille_entreprise as agg2_label',
        ],
        aggregationFunction: 'count_distinct',
        measure: 'siren',
        measureAlias: 'nb',
        table: 'opco2i_etab_ent',
        orderBy: ['num_taille_entreprise', 'nb'],
        orderDirection: 'DESC',
        filter: {
          branche: 'IS NOT NULL',
          num_branche: 'NOT IN (9999)',
          annee: 2024,
        },
      },
    } as Query,
  });

  return queries(filter) as Record<string, Query>;
};
type PagePart = 'part1' | 'part2' | 'part1interindus' | 'part2interindus';

const templateOptions = {
  part1: {
    sortOptions: [
      {
        id: '2',
        label:
          "1. Nombre d'entreprises dans la branche professionnelle au total",
      },
    ],
    graphTitle:
      'Répartition des entreprises par branche professionnelle en 2024',
    bqTable: 'opco2i_etab_ent',
    exportMesureFieldname: "Nombre d'entreprises",
    iconCard: {
      bgClass: 'bg-orange-100',
      icon: 'company-orange.svg',
    },
    navigationColor: '#EC6608',
    source:
      'Sources : Observatoire Compétences Industries (OPCO 2i), France Compétences et INSEE',
  },
  part2: {
    sections: [
      { label: "Toutes tailles d'entreprises", id: '0' },
      { label: 'Moins de 10 salariés', id: '1' },
      { label: '10 à 49 salariés', id: '10' },
      { label: '50 à 249 salariés', id: '50' },
      { label: '250 salariés et plus', id: '250' },
      { label: 'Non renseignée', id: '999' },
    ],
    sortOptions: [
      {
        id: '2',
        label:
          "1. Nombre d'entreprises dans la branche professionnelle au total",
      },
      {
        id: '3',
        label: "2. Nombre d'entreprises dans la catégorie sélectionnée",
      },
      {
        id: '4',
        label: "3. % d'entreprises dans la catégorie sélectionnée",
      },
    ],
    graphTitle: "Répartition des entreprises par taille d'entreprise en 2024",
    bqTable: 'opco2i_etab_ent',
    exportMesureFieldname: "Nombre d'entreprises",
    exportAgg2Fieldname: "Taille d'entreprise",
    agg2Field: 'num_taille_entreprise',
    navigationColor: '#EC6608',
    source:
      'Sources : Observatoire Compétences Industries (OPCO 2i), France Compétences et INSEE',
  },
};

const _sortFields = (sortid: SortId, section?: string): SortField => {
  switch (sortid) {
    case 4:
      return !!section && !!section.length && section !== '0' ? 'nb' : 'total';
    case 3:
      return 'absolute';
    case 1:
      return 'agg1_label';
    case 2:
      return 'total';
    default:
      return 'nb';
  }
};

export default function Entreprises({
  filters,
  initialKpiData,
}: EntreprisesProps) {
  const [kpiData, setKpiData] = useState<Record<PagePart, Query> | undefined>(
    undefined
  );
  const [activeFilters, setFiltersList] = useState<ActiveFilters>({});
  const [selectedSort, setSelectedSort] = useState<IdLabelObject | undefined>(
    templateOptions.part1.sortOptions[0]
  );
  const [selectedSortField, setSelectedSortField] = useState<SortField>(
    _sortFields(2)
  );
  const [_loading, setLoading] = useState<boolean>(true);
  const [popupRightButton, setPopupRightButton] = useState<string | undefined>(
    undefined
  );
  const [popupLeftButton, setPopupLeftButton] = useState<string | undefined>(
    undefined
  );
  const zIndex = useZIndex();
  const router = useRouter();

  useEffect(() => {
    if (!isEmpty(router.query) && !isEqual(router.query, activeFilters)) {
      setLoading(true);
      const _af: ActiveFilters = clone(activeFilters);
      Object.keys(router.query).forEach((k) => {
        const _f = find(filters, { fieldid: k });
        const _condition = router.query[k]?.length && !isEmpty(_f);
        _condition &&
          (_af[k] = (router.query[k] as string).split(',').map((_p: string) => {
            const _v = find(_f.values, {
              id: k.startsWith('num_') || k === 'annee' ? parseInt(_p) : _p,
            }) as IdLabelObject;
            return _v;
          }));
      });

      void _updateKpiData(_af, {
        after: () => {
          setLoading(false);
        },
      });
    }
  }, [router.query]);

  useEffect(() => {
    if (!isEmpty(initialKpiData) && router.isReady && isEmpty(router.query)) {
      setLoading(false);
    }
  }, [initialKpiData, router.isReady]);

  const _updateKpiData = async (
    selectedFilters: ActiveFilters,
    // eslint-disable-next-line @typescript-eslint/ban-types
    forced?: { before?: Function; after?: Function; inbetweencb?: Function }
  ) =>
    getKpiData(selectedFilters, _getPagesQueries, {
      before:
        forced?.before ??
        ((_fselection: ActiveFilters) => {
          !!forced && forced.inbetweencb?.();
          setFiltersList(_fselection);
        }),
      after: (_d: Record<PagePart, Query>) => {
        const _nonull = nullImputation(_d, 'nb', 0);
        setKpiData(_nonull);
        !!forced && forced.after?.();
      },
    });

  const _navigate = (pageid: string) => {
    const _goto = (page: string) => {
      void router.push(`/${page}`);
    };

    const _filterPart = Object.keys(activeFilters).reduce((acc, curr) => {
      const _f = activeFilters[curr]!;
      const _v = _f.map((_s) => _s.id).join(',');
      return _v.length ? { ...acc, [curr]: _v } : { ...acc };
    }, {});

    switch (pageid) {
      case '2':
        _goto(
          'etablissements' +
            (!isEmpty(_filterPart)
              ? '?' + new URLSearchParams(_filterPart).toString()
              : '')
        );
        break;
      case '3':
        _goto(
          'alternants' +
            (!isEmpty(_filterPart)
              ? '?' +
                new URLSearchParams(omit(_filterPart, ['annee'])).toString()
              : '')
        );
        break;
      case '4':
        _goto(
          'salaries' +
            (!isEmpty(_filterPart)
              ? '?' +
                new URLSearchParams(omit(_filterPart, ['annee'])).toString()
              : '')
        );
        break;
      case '5':
        _goto('cartes');
        break;
      default:
        break;
    }
  };

  const _handlers = {
    render: (component: JSX.Element) => {
      return _loading || !router.isReady ? (
        <GlobalLoader></GlobalLoader>
      ) : (
        component
      );
    },
    carousel: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      switch: (newPageid: number, cb?: Function) => {
        const _newPageid =
          newPageid < 0
            ? Object.keys(templateOptions).length + newPageid
            : newPageid % Object.keys(templateOptions).length;

        cb?.();

        // Update popup texts
        _handlers.carousel.navigation.popup(
          _newPageid,
          false,
          setPopupLeftButton
        );
        _handlers.carousel.navigation.popup(
          _newPageid,
          true,
          setPopupRightButton
        );
      },
      navigation: {
        // eslint-disable-next-line @typescript-eslint/ban-types
        popup: (newIndex: number, rightside: boolean, cb: Function) => {
          const _newidx = rightside ? newIndex + 1 : newIndex - 1;
          const _nextidx =
            _newidx < 0
              ? Object.keys(templateOptions).length + _newidx
              : _newidx % Object.keys(templateOptions).length;
          const _nextkey = Object.keys(templateOptions)[
            _nextidx
          ] as keyof typeof templateOptions;

          const _rpopuptext = templateOptions[_nextkey];

          cb(_rpopuptext.graphTitle || '');
        },
      },
    },
  };

  const setSort = (sort: IdLabelObject, section?: string) => {
    setSelectedSort(sort);
    const _sortField = _sortFields(parseInt(sort.id) as SortId, section);
    setSelectedSortField(_sortField);
  };

  return _handlers.render(
    <>
      <Head>
        <title>Entreprises - OPCO 2i par KYU Associés </title>
        <meta
          name='description'
          content='Entreprises - OPCO 2i par KYU Associés'
        />
      </Head>
      <div className='relative w-auto md:w-full min-h-screen bg-[#f2f4f9]'>
        <Navigation id='1' _navigate={_navigate} />
        <Carousel
          loop
          prevArrow={({ handlePrev, activeIndex }) => (
            <div
              className={clsx(
                '!absolute top-80 xl:top-56  left-[2.5rem] flex flex-row gap-2',
                `transition-opacity ease-in duration-700 ${zIndex.zIndexValues.select}`
              )}
            >
              <IconButton
                className='peer bg-[#ec6608]/90 shadow-lg rounded-md hover:bg-[#ec6608]/20'
                variant='text'
                color='white'
                size='md'
                onClick={() => {
                  _loading || !router.isReady
                    ? null
                    : _handlers.carousel.switch(activeIndex - 1, handlePrev);
                }}
                onMouseEnter={() => {
                  !popupLeftButton &&
                    _handlers.carousel.navigation.popup(
                      activeIndex,
                      false,
                      setPopupLeftButton
                    );
                }}
              >
                <ArrowSmallLeftIcon
                  fontSize={9}
                  strokeWidth={2}
                  className=' w-6 h-6'
                />
              </IconButton>
              <p className='-z-10 hidden peer-hover:block bg-[#ec6608]/90 p-2 absolute top-12 rounded-md text-[10px] text-white font-normal opacity-0 peer-hover:opacity-100 peer-hover:z-20 duration-1000'>
                {popupLeftButton && `Précédent: ${popupLeftButton}`}
              </p>
            </div>
          )}
          nextArrow={({ handleNext, activeIndex }) => (
            <div
              className={clsx(
                '!absolute top-80 xl:top-56 right-[2.5rem] flex flex-row gap-2',
                `transition-opacity ease-in duration-700 ${zIndex.zIndexValues.select}`
              )}
            >
              <IconButton
                className='peer bg-[#ec6608]/90 shadow-lg rounded-md hover:bg-[#ec6608]/20'
                variant='text'
                color='white'
                size='md'
                onClick={() => {
                  _loading || !router.isReady
                    ? null
                    : _handlers.carousel.switch(activeIndex + 1, handleNext);
                }}
                onMouseEnter={() => {
                  !popupRightButton &&
                    _handlers.carousel.navigation.popup(
                      activeIndex,
                      true,
                      setPopupRightButton
                    );
                }}
              >
                <ArrowSmallRightIcon
                  fontSize={9}
                  strokeWidth={2}
                  className='w-6 h-6'
                />
              </IconButton>
              <p className=' bg-[#ec6608]/90 p-2 -z-10 hidden peer-hover:block absolute top-12 right-0 rounded-md text-[10px] text-white font-normal opacity-0 peer-hover:opacity-100 peer-hover:z-20 duration-1000'>
                {popupRightButton && `Suivant: ${popupRightButton}`}
              </p>
            </div>
          )}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className='absolute bottom-4 left-2/4 z-50 hidden -translate-x-2/4 gap-2'>
              {new Array(length).fill('').map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                    activeIndex === i
                      ? 'bg-[#ec6608] w-8'
                      : 'bg-[#ec6608]/50 w-4'
                  }`}
                  onClick={() => {
                    setActiveIndex(i);
                  }}
                />
              ))}
            </div>
          )}
          className='rounded-xl w-full h-full'
        >
          <div className='relative h-full w-full sm:p-10 p-3 sm:pt-0 pt-0'>
            <TemplatePart1
              templateOptions={templateOptions.part1}
              key={1}
              filters={filters}
              kpiData={(kpiData?.part1 ?? initialKpiData.part1)!}
              initialKpiData={
                (kpiData?.part1interindus ?? initialKpiData.part1interindus)!
              }
              updateKpiData={_updateKpiData}
              commonSort={selectedSort}
              setSort={setSort}
              sortField={selectedSortField}
              activeFilters={activeFilters}
              // secretStatText="Aucune donnée disponible pour cette branche"
            />
          </div>
          <div className='relative h-full w-full sm:p-10 p-3 sm:pt-0 pt-0'>
            <TemplatePart2
              templateOptions={templateOptions.part2}
              key={2}
              filters={filters}
              kpiData={(kpiData?.part2 ?? initialKpiData.part2)!}
              initialKpiData={
                (kpiData?.part2interindus ?? initialKpiData.part2interindus)!
              }
              updateKpiData={_updateKpiData}
              commonSort={selectedSort}
              setSort={setSort}
              sortField={selectedSortField}
              activeFilters={activeFilters}
              // secretStatText='Aucune donnée disponible pour cette branche professionnelle'
            />
          </div>
        </Carousel>
      </div>
    </>
  );
}

// --------------------------------------------       StaticProps      -----------------------------------------------------------------------------
export const getStaticProps: GetStaticProps = async () => {
  // load filters
  const _ftoload = [
    {
      fieldid: 'num_branche',
      fieldlabel: 'branche',
      table: 'referentiel_branche',
      placeholder: 'Branche professionnelle',
      multiple: true,
    },
  ] as FilterSelect[];
  const filters = await loadFilters(_ftoload);
  // load initial kpi data // only the first time is needed here

  const queries = _getPagesQueries();
  const initialKpiData = await executeOnBigQuery(queries);

  // round all queries result to the nearest ten
  const rounded: Record<string, Query> = {};
  for (const key in initialKpiData) {
    rounded[key] = roundToTheNearestTen(initialKpiData[key]!);
  }

  return {
    props: {
      filters,
      initialKpiData: rounded,
    },
  };
};
